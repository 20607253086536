import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/conversations';
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Conversations API`}</h1>
    <p>{`The MessageBird Conversations API is an omnichannel messaging solution to unify sending and receiving messages across
all platforms in one conversation thread. A conversation is the view of all messages between you and a customer across
any of your configured channels.`}</p>
    <ul>
      <li parentName="ul">{`Messages from multiple channels and same contact are displayed in a single conversation`}</li>
      <li parentName="ul">{`When the first message is sent to or received from a contact, a conversation is automatically created`}</li>
      <li parentName="ul">{`A conversation status can be active or archived, but only one active conversation exists for each contact at any given
time`}</li>
      <li parentName="ul">{`Archiving can be used to end a conversation so that the next time the customer reaches out, a new conversation is
started. This is especially helpful for support use cases where conversations need to be opened and closed, rather than
merely resumed.`}</li>
    </ul>
    <p>{`The Conversations API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoints structure with an access key that is used as the
API Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <h3>{`Base URL`}</h3>
    <p>{`All paths referenced in this page uses the following base URL:`}</p>
    <CodeSnippet code={CODE.CAPI_BASE_URL} mdxType="CodeSnippet" />
    <h3>{`Authorization`}</h3>
    <p>{`You need to set an access key to authenticate your HTTP requests. You can create, manage, and retrieve your access keys
using the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`MessageBird Dashboard`}</a>{`.`}</p>
    <p>{`To provide the access key, set the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` HTTP header in the form of `}<inlineCode parentName="p">{`AccessKey {accessKey}`}</inlineCode>{`.`}</p>
    <h3>{`Formats and Headers`}</h3>
    <p>{`All HTTP responses are in JSON. It's advised to send the `}<inlineCode parentName="p">{`Accept`}</inlineCode>{` HTTP header with the value `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` with each
request. Both POST and PUT requests to the API must contain a JSON-formatted payload in the request body.`}</p>
    <h3>{`Rate limits`}</h3>
    <p>{`Conversations API will reject HTTP requests with status code `}<inlineCode parentName="p">{`429 Too Many Requests`}</inlineCode>{` in case the number of requests per
second (RPS) is higher than the following limits. The burst is the maximum RPS in a short period of time, and the limit
is the maximum RPS a long term. In short, read requests are limited to 500 RPS, and write/update requests depend on the
customer tier.`}</p>
    <Notification type="INFO" mdxType="Notification">
    Please <a href="https://www.messagebird.com/en/contact">contact support</a> in case you need higher limits.
    </Notification>
    <table>
    <thead>
        <tr>
            <td><strong>HTTP method</strong></td>
            <td colSpan="2"><strong>Regular customers</strong></td>
            <td colSpan="2"><strong>Enterprise customers</strong></td>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td></td>
            <td><strong>Limit</strong></td>
            <td><strong>Burst</strong></td>
            <td><strong>Limit</strong></td>
            <td><strong>Burst</strong></td>
        </tr>
        <tr>
            <td>GET</td>
            <td>500</td>
            <td>500</td>
            <td>500</td>
            <td>500</td>
        </tr>
        <tr>
            <td>POST</td>
            <td>50</td>
            <td>250</td>
            <td>500</td>
            <td>500</td>
        </tr>
        <tr>
            <td>PATCH</td>
            <td>50</td>
            <td>250</td>
            <td>500</td>
            <td>500</td>
        </tr>
        <tr>
            <td>DELETE</td>
            <td>50</td>
            <td>250</td>
            <td>500</td>
            <td>500</td>
        </tr>
    </tbody>
    </table>
    <h3>{`Errors`}</h3>
    <p>{`MessageBird uses standard HTTP status codes to indicate success or failure of API requests.`}</p>
    <ul>
      <li parentName="ul">{`HTTP response codes in the `}<inlineCode parentName="li">{`2xx`}</inlineCode>{` range indicate the request was successfully processed`}</li>
      <li parentName="ul">{`HTTP response codes in the `}<inlineCode parentName="li">{`4xx`}</inlineCode>{` range indicate that there was a client error - for example: authentication error,
not enough balance or wrong/missing parameters. Don't worry, the HTTP response body includes a JSON-formatted response
that tells you exactly what went wrong. If you're stuck, feel free to `}<a parentName="li" {...{
          "href": "https://www.messagebird.com/en/contact"
        }}>{`contact support`}</a>{`,
we're happy to help you out`}</li>
      <li parentName="ul">{`HTTP response codes in the `}<inlineCode parentName="li">{`5xx`}</inlineCode>{` range indicate a server error. It's considered safe to retry the same request,
preferably with a delay`}</li>
    </ul>
    <h4>{`Error object`}</h4>
    <CodeSnippet code={CODE.ERROR_OBJECT} mdxType="CodeSnippet" />
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An integer that represents the error type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A human-readable description of the error. You can use this to let the user know what they can do about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].parameter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The parameter in your request related to the error if the error is parameter specific.`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2>{`Sending Messages`}</h2>
    <p>{`The Conversations API provides three ways of sending messages: start conversation, send a message to a specific
conversation and send message. Regardless the way you send the messages, they are all processed asynchronously, which means,
Conversations API will accept your message and process it later, and, because of that, it's important to have webhooks
for receiving the status updates about the messages you send.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Send message`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Intended to send a high number of messages`}</li>
          <li parentName="ul">{`The HTTP response provides only the message ID and status`}</li>
          <li parentName="ul">{`If there's an existing active conversation with the contact, the message will add the message to the active conversation`}</li>
          <li parentName="ul">{`If there are no conversation with the contact or all conversations are archived, it will create a new active conversation`}</li>
          <li parentName="ul">{`It quickly accepts the HTTP request and does the same work as the following endpoint asynchronously`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Start conversation`}</strong>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Important:`}</strong>{` always prefer to use the endpoint for sending messages (`}<inlineCode parentName="li">{`POST /v1/send`}</inlineCode>{`), unless you need the conversation in the HTTP response`}</li>
          <li parentName="ul">{`Intended to be used to start new conversations`}</li>
          <li parentName="ul">{`If there's an existing active conversation with the contact, the message will add the message to the active conversation`}</li>
          <li parentName="ul">{`If there are no conversation with the contact or all conversations are archived, it will create a new active conversation`}</li>
          <li parentName="ul">{`The HTTP response provides the conversation ID and the contact details`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Reply to conversation`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Intended to be used to send a message to a specific conversation`}</li>
          <li parentName="ul">{`If the conversation is archived, the endpoint will respond `}<inlineCode parentName="li">{`400 Bad Request`}</inlineCode></li>
          <li parentName="ul">{`If the conversation doesn't exist, the endpoint will respond `}<inlineCode parentName="li">{`404 Not Found`}</inlineCode></li>
          <li parentName="ul">{`The HTTP response provides the message details, including the message ID and status`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Start Conversation`}</h3>
    <p>{`Starts a conversation with a specific contact. In case there's already an active conversation with the contact, the
message will be added to the existing active conversation. In case there's no active conversation with the contact, a
new conversation will be created.`}</p>
    <CodeSnippet code={CODE.START_CONVERSATION_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagetype-object"
            }}>{`MessageType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of the message content. Defines which field is required inside `}<inlineCode parentName="td">{`content`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagecontent-object"
            }}>{`MessageContent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content of the message to send. The value of `}<inlineCode parentName="td">{`type`}</inlineCode>{` defines the required fields.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagerecipient-object"
            }}>{`MessageRecipient`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A platform-specific identifier of the recipient.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that identifies the channel through which the message should be sent.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A JSON-formatted object that can be used to identify the source of the message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`reportUrl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL to deliver status updates for the message. Must use HTTPS.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagetag-object"
            }}>{`MessageTag`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mark the message with a particular MessageBird Message Tag. The meaning and effect of each tag depend on each specific platform.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`trackId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Useful for grouping messages across conversations. The limit is 36 characters.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`eventType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of event being sent. The value of the parameter `}<inlineCode parentName="td">{`type`}</inlineCode>{` must be a `}<inlineCode parentName="td">{`event`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ttl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only supported by WhatsApp. You can set a time period for delivering the message. After this time, the message will be rejected. Formatted as a short-hand duration, for example: "30m" for 30 minutes, "3h" for 3 hours. Minimum value is 15s.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`replyTo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#replyto-object"
            }}>{`ReplyTo`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Declare that the message is a reply to a previous message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.START_CONVERSATION_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <p>{`The HTTP response contains a `}<a parentName="p" {...{
        "href": "/api/conversations/#conversation-object"
      }}>{`Conversation`}</a>{` object.`}</p>
    <CodeSnippet code={CODE.START_CONVERSATION_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`201`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message accepted and it will be processed asynchronously.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`422`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP request is well-formed but was unable to be processed. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Reply Conversation`}</h3>
    <p>{`Send a new message to an existing conversation. In case the conversation is archived, a new conversation is created.`}</p>
    <CodeSnippet code={CODE.REPLY_TO_CONVERSATION_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagetype-object"
            }}>{`MessageType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of the message content. Defines which field is required inside `}<inlineCode parentName="td">{`content`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagecontent-object"
            }}>{`MessageContent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content of the message to send. The value of `}<inlineCode parentName="td">{`type`}</inlineCode>{` defines the required fields.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that identifies the channel over which the message should be sent. If not provided, the most-recently used active channel for the conversation is used.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fallback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#fallback-object"
            }}>{`Fallback`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required to send a `}<a parentName="td" {...{
              "href": "/api/conversations/#fallback-messages"
            }}>{`fallback message`}</a>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A JSON-formatted object that can be used to identify the source of the message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`eventType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of event being sent. The value of the parameter `}<inlineCode parentName="td">{`type`}</inlineCode>{` must be a `}<inlineCode parentName="td">{`event`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`reportUrl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL for delivering status updates for the message. Must be HTTPS.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagetag-object"
            }}>{`MessageTag`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mark the message with a particular MessageBird Message Tag. The meaning and effect of each tag depend on each specific platform.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`trackId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Useful for grouping messages across conversations. The limit is 36 characters.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ttl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only supported by WhatsApp. You can set a time period for delivering the message. After this time, the message will be rejected. Formatted as a short-hand duration, for example: "30m" for 30 minutes, "3h" for 3 hours. Minimum value is 15s.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`replyTo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#replyto-object"
            }}>{`ReplyTo`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Declare that the message is a reply to a previous message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.REPLY_TO_CONVERSATION_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h3>{`Response`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID generated by MessageBird that identifies the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conversationId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that identifies the conversation which the message is a part of.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that identifies the channel which the message was sent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`platform`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#platform-object"
            }}>{`Platform`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The platform name of the channel which the message was sent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagerecipient-object"
            }}>{`MessageRecipient`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the recipient in the platform. The value depends on the platform.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the sender in the platform. The value depends on the platform.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`direction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagedirection-object"
            }}>{`MessageDirection`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The direction of the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagestatus-object"
            }}>{`MessageStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagetype-object"
            }}>{`MessageType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of message content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagecontent-object"
            }}>{`MessageContent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content of the message. The `}<inlineCode parentName="td">{`type`}</inlineCode>{` field indicates the field which is populated in this object.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The datetime when the message was created in RFC3339 format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The datetime when the message was updated in RFC3339 format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A JSON-formatted object that can be used to identify the source of the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagetag-object"
            }}>{`MessageTag`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mark the message with a particular MessageBird Message Tag. The meaning and effect of each tag depend on each specific platform.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fallback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#fallback-object"
            }}>{`Fallback`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<a parentName="td" {...{
              "href": "/api/conversations/#fallback-messages"
            }}>{`fallback message`}</a>{`, if provided.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ttl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only supported by WhatsApp. Time limit for delivering the message. Formatted as a short-hand duration, for example: "30m" for 30 minutes, "3h" for 3 hours. Minimum value is 15s.`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.REPLY_TO_CONVERSATION_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`201`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message accepted and it will be processed asynchronously.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversation not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`422`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP request is well-formed but was unable to be processed. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Send Message`}</h3>
    <p>{`Send a message to a specific recipient in a specific platform. If an active conversation already exists for the recipient,
the conversation will be resumed. In case there's no active conversation a new one is created.`}</p>
    <CodeSnippet code={CODE.SEND_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Either a `}<a parentName="td" {...{
              "href": "/api/conversations/#messagerecipient-object"
            }}>{`MessageRecipient`}</a>{` or the MessageBird contact ID.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The channel ID from which the message should be sent.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagetype-object"
            }}>{`MessageType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of the message content. Defines which field is required inside `}<inlineCode parentName="td">{`content`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagecontent-object"
            }}>{`MessageContent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content of the message to send. The value of `}<inlineCode parentName="td">{`type`}</inlineCode>{` defines the required fields.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`reportUrl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL for delivering status updates for the message. Must be HTTPS.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fallback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#fallback-object"
            }}>{`Fallback`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required to send a `}<a parentName="td" {...{
              "href": "/api/conversations/#fallback-messages"
            }}>{`fallback message`}</a>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A JSON-formatted object that can be used to identify the source of the message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagetag-object"
            }}>{`MessageTag`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mark the message with a particular MessageBird Message Tag. The meaning and effect of each tag depend on each specific platform.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`trackId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Useful for grouping messages across conversations. The limit is 36 characters.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ttl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only supported by WhatsApp. You can set a time period for delivering the message. After this time, the message will be rejected. Formatted as a short-hand duration, for example: "30m" for 30 minutes, "3h" for 3 hours. Minimum value is 15s.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`replyTo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#replyto-object"
            }}>{`ReplyTo`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Declare that the message is a reply to a previous message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.SEND_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h3>{`Response`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An unique ID generated by MessageBird that identifies the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagestatus-object"
            }}>{`MessageStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fallback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#fallback-object"
            }}>{`Fallback`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<a parentName="td" {...{
              "href": "/api/conversations/#fallback-messages"
            }}>{`fallback message`}</a>{`, if provided.`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.SEND_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`202`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message accepted and it will be processed asynchronously.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`422`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP request is well-formed but was unable to be processed. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Fallback Messages`}</h3>
    <p>{`A fallback message is a message to be sent over a secondary channel if the original message failed or could not be
sent within a defined time window.`}</p>
    <p>{`For example, when sending a WhatsApp message to a customer, you can configure a fallback message so that if the customer
is not a WhatsApp user or doesn't have a mobile data connection, the message is then sent over SMS. You can define the
time at which the fallback message should be sent if it wasn't successful over the primary channel; if you don't set a
time, the fallback will automatically trigger after 1 minute.`}</p>
    <p>{`Currently, our Conversations API only supports fallbacks between SMS and WhatsApp. You can set a fallback for a WhatsApp
session message with SMS as the secondary channel, or the other way around. Please note that the fallback configuration
using WhatsApp media templates and location are not supported yet.`}</p>
    <Notification type="INFO" mdxType="Notification">
    It's important to understand that, when sending a fallback message to a secondary channel, the contact must have
    an identifier in the secondary platform, otherwise it won't be possible to send the message.
    </Notification>
    <p>{`Here is an example of sending a text message using a primary channel, and falling back to a secondary channel after 5
minutes.`}</p>
    <CodeSnippet code={CODE.FALLBACK_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <p>{`Here is the response for the request above.`}</p>
    <CodeSnippet code={CODE.FALLBACK_RESPONSE} mdxType="CodeSnippet" />
    <h4>{`Fallback Status Updates`}</h4>
    <p>{`In case the `}<inlineCode parentName="p">{`reportUrl`}</inlineCode>{` is provided in the request, and the fallback message was triggered, you'll also receive the
status update `}<inlineCode parentName="p">{`message.fallbackTriggered`}</inlineCode>{` in your report URL. The status update will include the original and fallback
message IDs.`}</p>
    <CodeSnippet code={CODE.FALLBACK_STATUS_UPDATE_EXAMPLE} mdxType="CodeSnippet" />
    <h2>{`Receiving Messages and Status Updates`}</h2>
    <p>{`In order to receive messages, you must set up webhooks in Conversations API. For receiving status updates of sent messages,
there are two ways of doing that: by using webhooks or per message. More details about these two approaches are in the
following sections.`}</p>
    <h3>{`Webhooks`}</h3>
    <p>{`Webhooks allow your application to receive conversation events, inbound messages and status updates of outbound messages.
A webhook can be configured with a URL and a list of events that should be subscribed for notifications. It's possible to
create multiple webhooks with different URLs to listen to one or more events.`}</p>
    <p>{`There are two types of webhooks: channel-specific, which subscribes to events of a particular channel; and generic
webhooks, which subscribes to events of all your channels.`}</p>
    <Notification type="INFO" mdxType="Notification">
    The current webhook limits are 10 channel-specific webhooks and 5 generic webhooks.
    </Notification>
    <h4>{`Create Webhook`}</h4>
    <p>{`Creates a webhook.`}</p>
    <CodeSnippet code={CODE.CREATE_WEBHOOK_ENDPOINT} mdxType="CodeSnippet" />
    <h5>{`Request`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`events`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/conversations/#webhookevents-object"
            }}>{`WebhookEvents`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A list of event names that should trigger this webhook.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique identifier for a MessageBird channel that this webhook is subscribed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The endpoint URL that requests should be sent to.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`settings`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#webhooksettings-object"
            }}>{`WebhookSettings`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The webhook extra settings.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.CREATE_WEBHOOK_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response`}</h5>
    <p>{`The HTTP response contains a `}<a parentName="p" {...{
        "href": "/api/conversations/#webhook-object"
      }}>{`Webhook`}</a>{` object.`}</p>
    <CodeSnippet code={CODE.CREATE_WEBHOOK_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h6>{`Response codes`}</h6>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`201`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Webhook created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`422`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP request is well-formed but was unable to be processed. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`List Webhooks`}</h4>
    <p>{`Retrieves a list of webhooks.`}</p>
    <CodeSnippet code={CODE.LIST_WEBHOOKS_ENDPOINT} mdxType="CodeSnippet" />
    <h5>{`Request`}</h5>
    <CodeSnippet code={CODE.LIST_WEBHOOKS_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of webhooks.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#webhook-object"
            }}>{`Webhook`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of webhook objects.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of maximum objects returned on each request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of objects skipped.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of total webhook objects that can be retrieved through pagination.`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.LIST_WEBHOOKS_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h6>{`Response codes`}</h6>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ok.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`422`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP request is well-formed but was unable to be processed. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Get Webhook`}</h4>
    <p>{`Retrieves a specific webhook.`}</p>
    <CodeSnippet code={CODE.GET_WEBHOOK_ENDPOINT} mdxType="CodeSnippet" />
    <h5>{`Request`}</h5>
    <CodeSnippet code={CODE.GET_WEBHOOK_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response`}</h5>
    <p>{`The HTTP response contains a `}<a parentName="p" {...{
        "href": "/api/conversations/#webhook-object"
      }}>{`Webhook`}</a>{` object.`}</p>
    <CodeSnippet code={CODE.GET_WEBHOOK_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h6>{`Response codes`}</h6>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ok.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Webhook not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Update Webhook`}</h4>
    <p>{`Updates a webhook with the parameters provided. Only parameters that will change need to be provided in the request.`}</p>
    <CodeSnippet code={CODE.UPDATE_WEBHOOK_ENDPOINT} mdxType="CodeSnippet" />
    <h5>{`Request`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`events`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/conversations/#webhookevents-object"
            }}>{`WebhookEvents`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A list of event names that should trigger this webhook.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The endpoint URL that requests should be sent to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#webhookstatus-object"
            }}>{`WebhookStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The webhook status.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`settings`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#webhooksettings-object"
            }}>{`WebhookSettings`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The webhook extra settings.`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.UPDATE_WEBHOOK_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response`}</h5>
    <p>{`The HTTP response contains a `}<a parentName="p" {...{
        "href": "/api/conversations/#webhook-object"
      }}>{`Webhook`}</a>{` object.`}</p>
    <CodeSnippet code={CODE.UPDATE_WEBHOOK_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h6>{`Response codes`}</h6>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ok.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Webhook not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Delete Webhook`}</h4>
    <p>{`Disables and removes an existing webhook.`}</p>
    <CodeSnippet code={CODE.DELETE_WEBHOOK_ENDPOINT} mdxType="CodeSnippet" />
    <h5>{`Request`}</h5>
    <CodeSnippet code={CODE.DELETE_WEBHOOK_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response`}</h5>
    <CodeSnippet code={CODE.DELETE_WEBHOOK_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h6>{`Response codes`}</h6>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`204`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Webhook deleted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Webhook not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Handling Webhook Requests`}</h3>
    <p>{`HTTP requests will be delivered to your application whenever one of the events you have subscribed is triggered.
Each event has a specific payload which is delivered as a POST request.`}</p>
    <p>{`A HTTP request is considered successfully accepted when your application responds with any successful response (2xx).
Any other HTTP response code will be treated as not accepted. Not-accepted HTTP requests will be retried up to 10 times
over the next 24-hours period.`}</p>
    <Notification type="WARNING" mdxType="Notification">
    If your application fails to accept more than 90% of the HTTP requests in the last 12 hours, with a minimum of 200
    HTTP requests, your webhook will be automatically disabled and must be re-enabled via the UPDATE method. You will automatically
    receive a notification when your webhook is disabled. It can be found in the [MessageBird Dashboard Notifications Center](https://dashboard.messagebird.com/notifications)
    and you can subscribe to the notification event through your desired emails and webhooks.
    </Notification>
    <h4>{`Conversation Created Event`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#webhookevents-object"
            }}>{`WebhookEvents`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event type (`}<inlineCode parentName="td">{`conversation.created`}</inlineCode>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`contact`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#contact-object"
            }}>{`Contact`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact of the new conversation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conversation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#conversation-object"
            }}>{`Conversation`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`New conversation`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`Conversation created event example`}</h5>
    <CodeSnippet code={CODE.CONVERSATION_CREATED_EVENT_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Conversation Updated Event`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#webhookevents-object"
            }}>{`WebhookEvents`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event type (`}<inlineCode parentName="td">{`conversation.updated`}</inlineCode>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`contact`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#contact-object"
            }}>{`Contact`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact of the updated conversation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conversation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#conversation-object"
            }}>{`Conversation`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Updated conversation`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`Conversation updated event example`}</h5>
    <CodeSnippet code={CODE.CONVERSATION_UPDATED_EVENT} mdxType="CodeSnippet" />
    <h4>{`Conversation Deleted Event`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#webhookevents-object"
            }}>{`WebhookEvents`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event type (`}<inlineCode parentName="td">{`conversation.deleted`}</inlineCode>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`contact`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#contact-object"
            }}>{`Contact`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact of the updated conversation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conversation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#conversation-object"
            }}>{`Conversation`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Updated conversation`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`Conversation deleted event example`}</h5>
    <CodeSnippet code={CODE.CONVERSATION_DELETED_EVENT} mdxType="CodeSnippet" />
    <h4>{`Message Created Event`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#webhookevents-object"
            }}>{`WebhookEvents`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event type (`}<inlineCode parentName="td">{`message.created`}</inlineCode>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`contact`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#contact-object"
            }}>{`Contact`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact that received/sent the message`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conversation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#conversation-object"
            }}>{`Conversation`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversation which the message was added`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#message-object"
            }}>{`Message`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`New message`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`Message created event example`}</h5>
    <CodeSnippet code={CODE.MESSAGE_CREATED_EVENT} mdxType="CodeSnippet" />
    <h4>{`Message Updated Event`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#webhookevents-object"
            }}>{`WebhookEvents`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event type (`}<inlineCode parentName="td">{`message.updated`}</inlineCode>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`contact`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#contact-object"
            }}>{`Contact`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact object`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conversation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#conversation-object"
            }}>{`Conversation`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversation which the message was updated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#message-object"
            }}>{`Message`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Updated message`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`Message updated event example`}</h5>
    <CodeSnippet code={CODE.MESSAGE_UPDATED_EVENT} mdxType="CodeSnippet" />
    <h3>{`Status update per message`}</h3>
    <p>{`When sending a message, it's possible to set a different webhook URL per message basis using the `}<inlineCode parentName="p">{`reportUrl`}</inlineCode>{` parameter.
The requests contain information about the status of the message, and, in the event of a delivery failure, the payload
will contain the failure details.`}</p>
    <CodeSnippet code={CODE.STATUS_UPDATE_PER_MESSAGE_EXAMPLE} mdxType="CodeSnippet" />
    <hr></hr>
    <h2>{`Conversations`}</h2>
    <h3>{`List Conversations`}</h3>
    <p>{`Retrieves all conversations sorted by the `}<inlineCode parentName="p">{`lastReceivedDatetime`}</inlineCode>{` field so that all conversations with new messages appear first.`}</p>
    <CodeSnippet code={CODE.LIST_CONVERSATIONS_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of maximum objects to return on each request. The default value is 10 and the maximum is 20.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of objects to skip from the first. The default value is 10.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ids`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comma seperated list of conversation IDs to filter. The maximum number of IDs allowed for each request is 20.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#conversationstatus-object"
            }}>{`ConversationStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Filter based on status of the conversation. The default value is `}<inlineCode parentName="td">{`active`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.LIST_CONVERSATIONS_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of conversations returned.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#conversation-object"
            }}>{`Conversation`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of Conversation objects.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of maximum objects returned on each request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of objects skipped.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of total conversation objects that can be retrieved through pagination.`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.LIST_CONVERSATIONS_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ok.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Get Conversation`}</h3>
    <p>{`Retrieves a specific conversation.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that identifies the conversation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.GET_CONVERSATION_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <CodeSnippet code={CODE.GET_CONVERSATION_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <p>{`The HTTP response contains a `}<a parentName="p" {...{
        "href": "/api/conversations/#conversation-object"
      }}>{`Conversation`}</a>{` object.`}</p>
    <CodeSnippet code={CODE.GET_CONVERSATION_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ok.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversation not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Update Conversation`}</h3>
    <p>{`Updates a specific conversation.`}</p>
    <CodeSnippet code={CODE.UPDATE_CONVERSATION_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#conversationstatus-object"
            }}>{`ConversationStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The new status of the conversation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.UPDATE_CONVERSATION_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <p>{`The HTTP response contains a `}<a parentName="p" {...{
        "href": "/api/conversations/#conversation-object"
      }}>{`Conversation`}</a>{` object.`}</p>
    <CodeSnippet code={CODE.UPDATE_CONVERSATION_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ok.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversation not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`422`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An active conversation already exist.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Get Conversations by Contact`}</h3>
    <p>{`Retrieves the list of conversation IDs of a specific contact ID.`}</p>
    <CodeSnippet code={CODE.LIST_CONVERSATIONS_BY_CONTACT_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The MessageBird contact ID.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of maximum objects to return on each request. The default value is 10 and the maximum is 20.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of objects to skip from the first. The default value is 10.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#conversationstatus-object"
            }}>{`ConversationStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Filter the results by a specific conversation status. The default value is `}<inlineCode parentName="td">{`all`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.LIST_CONVERSATIONS_BY_CONTACT_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of conversations returned.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of conversation IDs.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of maximum objects returned on each request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of objects skipped.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of total conversation objects that can be retrieved through pagination.`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.LIST_CONVERSATIONS_BY_CONTACT_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ok.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2>{`Messages`}</h2>
    <h3>{`Get Messages in Conversation`}</h3>
    <p>{`Retrieves the messages of a specific conversation.`}</p>
    <CodeSnippet code={CODE.GET_MESSAGES_IN_CONVERSATION_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that identifies the conversation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of maximum objects to return on each request. The default value is 10 and maximum is 20.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of objects skipped.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`excludePlatforms`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of `}<a parentName="td" {...{
              "href": "/api/conversations/#platform-object"
            }}>{`Platform`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ignore messages from the specific platforms (separated by comma). Limited to 10 platforms.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.GET_MESSAGES_IN_CONVERSATION_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of messages returned.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#message-object"
            }}>{`Message`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of message objects.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of maximum objects returned on each request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of objects skipped.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of total message objects that can be retrieved through pagination.`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.GET_MESSAGES_IN_CONVERSATION_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ok.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversation not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Get Message`}</h3>
    <p>{`Retrieves a message given a specific ID.`}</p>
    <CodeSnippet code={CODE.GET_MESSAGE_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that identifies the message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.GET_MESSAGE_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <p>{`The HTTP response contains a `}<a parentName="p" {...{
        "href": "/api/conversations/#message-object"
      }}>{`Message`}</a>{` object.`}</p>
    <CodeSnippet code={CODE.GET_MESSAGE_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ok.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`List Messages`}</h3>
    <p>{`Retrieves a list of messages given a list of message IDs or a timestamp (not both).`}</p>
    <CodeSnippet code={CODE.GET_MESSAGES_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ids`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comma separated message IDs. Maximum of 20 messages.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No, if `}<inlineCode parentName="td">{`from`}</inlineCode>{` is provided`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A datetime in the RFC3339 format which specifies since when messages should be retrieved. Must be maximum 5 minutes.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No, if `}<inlineCode parentName="td">{`ids`}</inlineCode>{` is provided`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.GET_MESSAGES_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#message-object"
            }}>{`Message`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of message objects.`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.GET_MESSAGES_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ok.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many concurrent requests. Please retry the request with standard exponential backoff.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`499`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client closed the connection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2>{`Objects Reference`}</h2>
    <h3>{`Conversation object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An unique ID generated by MessageBird that identifies the conversation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`contactId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The MessageBird contact ID for the conversation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`contact`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#contact-object"
            }}>{`Contact`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The MessageBird Contact for this conversation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channels`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#channel-object"
            }}>{`Channel`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A series of Channels through which the contact of the conversation can be reached. This attribute can be empty if the conversation doesn't have any channels yet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#conversationstatus-object"
            }}>{`ConversationStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the conversation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time when the conversation was created in RFC3339 format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time when the conversation was most recently updated in RFC3339 format. It only applies to changes in the conversation object itself, not its messages.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lastReceivedDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time when the most recent message was added to the conversation in RFC3339 format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lastUsedChannelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An unique ID for the most recently used channel that sent or received a message to the conversation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lastUsedPlatformId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#platform-object"
            }}>{`Platform`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The platform name of the channel which most recently sent or received a message in the conversation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`messages`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messages-object"
            }}>{`Messages`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A link to the messages of this conversation`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Contact object`}</h3>
    <p>{`Contacts are a representation of an end-user you are communicating with; contacts are also available through the REST API via the `}<a parentName="p" {...{
        "href": "/api/contactsv2"
      }}>{`Contacts API`}</a>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID generated by the MessageBird platform that identifies this contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`href`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL of this contact object.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`msisdn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The phone number of this contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`firstName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The first name of this contact. Where the first name is not known, `}<inlineCode parentName="td">{`msisdn`}</inlineCode>{` will be used as the `}<inlineCode parentName="td">{`firstName`}</inlineCode>{` instead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lastName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The last name of this contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`customDetails`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional platform specific details about this contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The datetime when the message was created (in RFC3339 format).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The datetime when the message was updated (in RFC3339 format).`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Channel object`}</h3>
    <p>{`A channel enables you to send and receive messages through a platform (WhatsApp, Telegram, Email, etc.). A conversation
may contain messages of multiple channels simultaneously. You can configure your channels using the
`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels Overview`}</a>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID generated by the MessageBird platform that identifies this channel.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of this channel (configured through the MessageBird Dashboard).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`platformId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#platform-object"
            }}>{`Platform`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The platform name. For example: `}<inlineCode parentName="td">{`sms`}</inlineCode>{`, `}<inlineCode parentName="td">{`whatsapp`}</inlineCode>{`, `}<inlineCode parentName="td">{`email`}</inlineCode>{`, etc..`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#channelstatus-object"
            }}>{`ChannelStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the channel.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The datetime when the message was created (in RFC3339 format).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The datetime when the message was updated (in RFC3339 format).`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`ChannelStatus object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Platform`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The channel is active and, therefore, can send and receive messages.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`activating`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The channel is activating and it should become active soon.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`activation_code_required`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<a parentName="td" {...{
              "href": "https://support.messagebird.com/hc/en-us/articles/4419707830161-WhatsApp-Channel-Activation"
            }}>{`activation code`}</a>{` is necessary to activate the channel.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`activation_required`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://support.messagebird.com/hc/en-us/articles/4419707830161-WhatsApp-Channel-Activation"
            }}>{`Activation process`}</a>{` is necessary.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`activation_failed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://support.messagebird.com/hc/en-us/articles/4419707830161-WhatsApp-Channel-Activation"
            }}>{`Activation process`}</a>{` has failed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unavailable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The channel is temporarily unavailable.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_verified`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://support.messagebird.com/hc/en-us/articles/360012920877-Verifying-your-Facebook-Business-Account"
            }}>{`The business wasn't verified in Meta`}</a>{`, so the channel can't send or receive messages.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`inactive`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The channel is inactive and, therefore, can't send or receive messages.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The channel is pending and, therefore, can't send or receive messages.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action_required`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An action is required to activate the channel.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`deleted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The channel is deleted and, therefore, cannot be used anymore.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Messages object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`href`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A link to the endpoint to retrieve messages of the conversation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total number of messages that can be retrieved through pagination for the conversation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lastMessageId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID generated by MessageBird that identifies the latest message.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Webhook object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID generated by MessageBird that identifies the webhook.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`events`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/conversations/#webhookevents-object"
            }}>{`WebhookEvents`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A list of event names that should trigger this webhook.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique identifier for a MessageBird channel that this webhook is subscribed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`messageId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique message identifier for a message associated to the webhook.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The endpoint URL that requests should be sent to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#webhookstatus-object"
            }}>{`WebhookStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The webhook status: `}<inlineCode parentName="td">{`enabled`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time when the webhook was created (in RFC3339 format).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time when the webhook was updated (in RFC3339 format).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`settings`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#webhooksettings-object"
            }}>{`WebhookSettings`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The webhook extra settings.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WebhookEvents object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conversation.created`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A new conversation has been created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conversation.updated`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A conversation has been updated with a new status.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conversation.deleted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A conversation has been deleted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`message.created`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A new message has been created. Triggered for both sent and received messages.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`message.updated`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A message has been updated with a new status.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WebhookStatus object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The webhook is enabled, therefore events will be delivered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The webhook is disabled, therefore events won't be delivered.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WebhookSettings object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`settings.expected_http_code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The expected HTTP status code mask (like: 2xx, 200 and 201).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`settings.headers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hash`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Custom HTTP headers for the outgoing request (max length: 1024).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`settings.username`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Basic auth username (max length: 255).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`settings.password`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Basic auth password (max length: 255).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`settings.query_params`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The query parameters (max length: 2048).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`settings.retry`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of maximum retries (between 0-10).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`settings.timeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request timeout in seconds (between 1-30).`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Message object`}</h3>
    <p>{`Messages that have been sent by, or received from, a customer are automatically grouped in a conversation. Any messages
sent through the API or received from your customer across any of your configured channels can be retrieved via the
messages endpoints. Messages are returned from the API in the order they were created, with newest messages returned first.
Certain message types are channel specific such as a Highly Structured Message (HSM), which must be pre-approved
by Facebook.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID generated by the MessageBird platform that identifies this message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`conversationId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that identifies the conversation that this message is a part of.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that identifies the channel that the message was sent or received on.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`platform`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#platform-object"
            }}>{`Platform`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The platform name of the channel which the message was sent or received.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagerecipient-object"
            }}>{`MessageRecipient`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that identifies the message recepient. The value depends on platform.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID that identifies the message sender. The value depends on platform.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`direction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagedirection-object"
            }}>{`MessageDirection`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The direction of the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagestatus-object"
            }}>{`MessageStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagetype-object"
            }}>{`MessageType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of the message content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagecontent-object"
            }}>{`MessageContent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content of the message. The `}<inlineCode parentName="td">{`type`}</inlineCode>{` field indicates the fields that will be populated in this object.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The datetime when the message was created (in RFC3339 format).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The datetime when the message was updated (in RFC3339 format).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A JSON-formatted object that can be used to identify the source of the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagetag-object"
            }}>{`MessageTag`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MessageBird Message Tag. The meaning and effect of each tag depend on each specific platform.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ttl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only supported by WhatsApp. Time limit for delivering the message. Formatted as a short-hand duration, for example: "30m" for 30 minutes, "3h" for 3 hours. Minimum value is 15s.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referral`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagereferral-object"
            }}>{`MessageReferral`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only supported by WhatsApp. Contains information about from where the recipient came from.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`replyTo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#replyto-object"
            }}>{`ReplyTo`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Declare that the message is a reply to a previous message`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`MessageReferral object`}</h3>
    <p>{`The message referral object contains information about where the recipient came from.`}</p>
    <Notification type="INFO" mdxType="Notification">
    Only supported by WhatsApp at the moment.
    </Notification>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`whatsapp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappreferral-object"
            }}>{`WhatsAppReferral`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains information about the advertisement or Facebook post which was clicked by the recipient.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppReferral object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`headline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Title of the advertisement or Facebook post that generated the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Body of the advertisement or Facebook post that generated the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappreferralsource-object"
            }}>{`WhatsAppReferralSource`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object that contains information about the advertisement or Facebook post.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`media`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappreferralmedia-object"
            }}>{`WhatsAppReferralMedia`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object that contains information about the media used in the advertisement or Facebook post.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppReferralSource object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappreferralsourcetype-object"
            }}>{`WhatsAppReferralSourceType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Referral source type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL of the advertisement or Facebook post.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook ID of the advertisement or Facebook post.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppReferralMedia object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappreferralmediatype-object"
            }}>{`WhatsAppReferralMediaType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Referral media type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Media URL.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppReferralSourceType object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ad`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Advertisement`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`post`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook post`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppReferralMediaType object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Platform object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sms`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`whatsapp`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`facebook`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`telegram`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`line`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wechat`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`email`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`email_freemium`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`events`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`whatsapp_sandbox`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`apple`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`twitter`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`viber`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`livechat`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`instagram`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`googlebm`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`MessageContent object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Platforms`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`text`}</inlineCode>{`. The plain-text content of the message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Multiple`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#media-object"
            }}>{`Media`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`image`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Multiple`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#media-object"
            }}>{`Media`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`video`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Multiple`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`audio`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#media-object"
            }}>{`Media`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`audio`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Multiple`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`file`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#media-object"
            }}>{`Media`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`file`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Multiple`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#location-object"
            }}>{`Location`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`location`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Multiple`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`buttons`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#buttons-object"
            }}>{`Buttons`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`buttons`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Multiple`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hsm`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#messagehsm-object"
            }}>{`MessageHSM`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`hsm`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`interactive`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappinteractive-object"
            }}>{`WhatsAppInteractive`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`interactive`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`email`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#email-object"
            }}>{`Email`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`email`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`externalAttachments`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#media-object"
            }}>{`Media`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for story mentions.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instagram`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`disableUrlPreview`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional field for type `}<inlineCode parentName="td">{`text`}</inlineCode>{`. When set to `}<inlineCode parentName="td">{`true`}</inlineCode>{`, conversations will try to disable previews for urls in your text messages. Default value is `}<inlineCode parentName="td">{`false`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`whatsappSticker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappsticker-object"
            }}>{`WhatsAppSticker`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp sticker message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`whatsappOrder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsapporder-object"
            }}>{`WhatsAppOrder`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound WhatsApp order message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`whatsappText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsapptext-object"
            }}>{`WhatsAppText`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound WhatsApp text message. Used for questions about products.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`facebookQuickReply`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookmessage-object"
            }}>{`FacebookMessage`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook-specific message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`facebookMediaTemplate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookmessage-object"
            }}>{`FacebookMessage`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook-specific message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`facebookGenericTemplate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookmessage-object"
            }}>{`FacebookMessage`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook-specific message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`facebookReferral`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookreferral-object"
            }}>{`FacebookReferral`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook-specific referral message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lineSticker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#linesticker-object"
            }}>{`LineSticker`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line-specific sticker message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`MessageRecipient object`}</h3>
    <p>{`It represents the identification of a specific recipient in a platform. For example, in SMS platform, identifiers are
the phone numbers (MSISDN). There are a few types of identifiers:`}</p>
    <ul>
      <li parentName="ul">{`Phone number`}</li>
      <li parentName="ul">{`Email address`}</li>
      <li parentName="ul">{`Platform-specific: the platform assigns a universal identifier to every recipient`}</li>
      <li parentName="ul">{`Channel-specific: the identifier of the recipients varies from channel to channel`}</li>
    </ul>
    <p>{`The following table displays the identifiers for every supported platform.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Platform`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Identifier`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SMS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel-specific`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Telegram`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Platform-specific`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LINE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel-specific`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`WeChat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Platform-specific`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`E-mail address`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Twitter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Platform-specific`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Viber`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Livechat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel-specific`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Google Business Messages`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel-specific`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Instagram`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel-specific`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Fallback object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID that identifies the channel over which the message should be sent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`after`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is optional. You can set a time period before attempting to send the fallback. After this time, if the original message isn't in a successfully delivered state, the fallback will be triggered. Formatted as a short-hand duration, for example: "30m" for 30 minutes, "3h" for 3 hours. If the fallback time period isn't specified, 1 minute will be used as the default value.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Media object`}</h3>
    <p>{`For more information about the media types supported per platform, please check the section
`}<a parentName="p" {...{
        "href": "/api/conversations/#supported-media-types-per-platform"
      }}>{`Supported media types per platform`}</a>{`.`}</p>
    <Notification type="INFO" mdxType="Notification">
    For media on inbound messages, URLs that start with media.messagebird.com will not require an authorization key. For all other media hosted by MessageBird, see below.
    </Notification>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The url of the remote media file. If the media is hosted inside MessageBird, you will need to set the `}<inlineCode parentName="td">{`Authorization`}</inlineCode>{` header with your MessageBird `}<inlineCode parentName="td">{`AccessKey`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`caption`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The caption associated with the media file`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Location object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`latitude`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The latitude coordinates of the location.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`longitude`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The longitude coordinates of the location.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Buttons object`}</h3>
    <p>{`Messages with quick reply buttons. These can be sent over the following platforms; Line, Google Business Messaging, and OCW Livechat.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text to be sent with the quick replies. Can be a maximum of 300 characters`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/conversations/#buttonitem-object"
            }}>{`ButtonItem`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The button items to be sent as part of the message.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Below are the platform limitations for the maximum amount of buttons that can be attached to a message.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Platform`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Max buttons`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Line`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Google Business Messaging`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OCW Livechat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`ButtonItem object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text shown on the button. Can be a maximum 255 characters.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#buttonitemaction-object"
            }}>{`Button action`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action that the button will perform.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`ButtonItemAction object`}</h5>
    <p>{`OCW Livechat and Google Business Messaging support only `}<inlineCode parentName="p">{`text`}</inlineCode>{` ButtonItemActionType buttons, other types sent via these platforms will fallback to text buttons.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#buttonitemactiontype-object"
            }}>{`ButtonItemActionType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Required when `}<inlineCode parentName="td">{`type`}</inlineCode>{` is set to `}<inlineCode parentName="td">{`uri`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`ButtonItemActionType object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sends a text quick reply.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Open the location screen.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`camera`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Open the users camera.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`camera_roll`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Open the users camera roll.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Open the uri specified.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example json for a sending a buttons message via the `}<inlineCode parentName="p">{`/v1/send`}</inlineCode>{` endpoint to a Line user`}</p>
    <CodeSnippet code={CODE.LINE_BUTTONS_SEND_REQUEST} mdxType="CodeSnippet" />
    <h3>{`MessageHSM object`}</h3>
    <p>{`HSM stands for "Highly Structured Message" and can only be sent over a WhatsApp channel.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`namespace`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. WhatsApp namespace for your account. You will receive this value when setting up your WhatsApp account.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`templateName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. Template name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#hsmlanguage-object"
            }}>{`HSMLanguage`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message language.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`params`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#hsmlocalizableparameters-object"
            }}>{`HSMLocalizableParameters`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameters for regular templates.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`components`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#messagecomponent-object"
            }}>{`MessageComponent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameters for media templates.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`HSMLanguage object`}</h3>
    <p>{`Until January 2020, WhatsApp supported two possible values for `}<inlineCode parentName="p">{`hsm.language.policy`}</inlineCode>{`: `}<inlineCode parentName="p">{`deterministic`}</inlineCode>{` and `}<inlineCode parentName="p">{`fallback`}</inlineCode>{` (deprecated).
Deterministic will deliver the message template using the language and locale asked for, and fallback was used to deliver
the message template using the user's device language, if the language couldn't be determined, then the fallback language
is used. More details are available in the
`}<a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/whatsapp/message-templates/sending#language"
      }}>{`Language section of Whatsapp documentation page`}</a>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`policy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default value: `}<inlineCode parentName="td">{`deterministic`}</inlineCode>{`. The `}<inlineCode parentName="td">{`fallback`}</inlineCode>{` value was deprecated in January 2020.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. The code of the language or locale to use. It must correspond to one of the languages approved in the WhatsApp template you're using to send a message.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`HSMLocalizableParameters object`}</h3>
    <p>{`Some of the template parameters related to date/time and currency are localizable and can be displayed based on a
customer's device language and local preferences. Default values are used when localization fails.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. Default value of the parameter, it is used when localization fails. The only field needed when specifying parameter value that doesn't require localization.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`currency`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can be present only if dateTime object is not present. An object of the form `}<inlineCode parentName="td">{`{"currencyCode": "required string of ISO 4217 currency code", "amount": "required integer of total amount together with cents as a float, multiplied by 1000"}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dateTime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can be present only if currency object is not present. RFC3339 representation of the date and time.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`MessageComponent object`}</h3>
    <p>{`In a media template, there is a layout which is consists of header, body and footer. Header and body are configurable by
a user input in component property.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. Value could be set to `}<inlineCode parentName="td">{`header`}</inlineCode>{`, `}<inlineCode parentName="td">{`body`}</inlineCode>{`, `}<inlineCode parentName="td">{`button`}</inlineCode>{` or `}<inlineCode parentName="td">{`carousel`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sub_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Required when type is set to `}<inlineCode parentName="td">{`button`}</inlineCode>{`. Supported values are: `}<inlineCode parentName="td">{`quick_reply`}</inlineCode>{` and `}<inlineCode parentName="td">{`url`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`index`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Required when type is set to `}<inlineCode parentName="td">{`button`}</inlineCode>{`. You can have up to 3 buttons using index values of 0-2.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`parameters`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#messageparam-object"
            }}>{`MessageParam`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. Parameters to be used in the template message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cards`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#whatsappcarouselcard-object"
            }}>{`WhatsAppCarouselCard`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required when `}<inlineCode parentName="td">{`type`}</inlineCode>{` is `}<inlineCode parentName="td">{`carousel`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`MessageParam object`}</h3>
    <p>{`Component parameters are the values that are replaced in the template message. The possible types are `}<inlineCode parentName="p">{`text`}</inlineCode>{`, `}<inlineCode parentName="p">{`payload`}</inlineCode>{`,
`}<inlineCode parentName="p">{`image`}</inlineCode>{`, `}<inlineCode parentName="p">{`document`}</inlineCode>{`, `}<inlineCode parentName="p">{`currency`}</inlineCode>{`, `}<inlineCode parentName="p">{`video`}</inlineCode>{` and `}<inlineCode parentName="p">{`dateTime`}</inlineCode>{`.`}</p>
    <p>{`The parameter types are dependent on the component types. The possible values for each component type is as follows:`}</p>
    <ul>
      <li parentName="ul">{`Header: `}<inlineCode parentName="li">{`image`}</inlineCode>{`, `}<inlineCode parentName="li">{`document`}</inlineCode>{` or `}<inlineCode parentName="li">{`video`}</inlineCode></li>
      <li parentName="ul">{`Body : `}<inlineCode parentName="li">{`text`}</inlineCode>{`, `}<inlineCode parentName="li">{`currency`}</inlineCode>{` or `}<inlineCode parentName="li">{`datetime`}</inlineCode></li>
      <li parentName="ul">{`Button: `}<inlineCode parentName="li">{`text`}</inlineCode>{` or `}<inlineCode parentName="li">{`payload`}</inlineCode>{` (maximum 3 buttons are only possible)`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#templatemediatype-object"
            }}>{`TemplateMediaType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. Defines the parameter type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`text`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`payload`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`payload`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`currency`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#hsmcurrency-object"
            }}>{`HSMCurrency`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`currency`}</inlineCode>{`. Defines the currency details.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dateTime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`date_time`}</inlineCode>{` Can be present only if currency object is not present. RFC3339 representation of the date and time.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`document`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#mediamessageparam-object"
            }}>{`MediaMessageParam`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`document`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#mediamessageparam-object"
            }}>{`MediaMessageParam`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`image`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#mediamessageparam-object"
            }}>{`MediaMessageParam`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for type `}<inlineCode parentName="td">{`video`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`MediaMessageParam object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL of the remote media file.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`TemplateMediaType object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Image. It can be used when the component type (`}<inlineCode parentName="td">{`hsm.components.type`}</inlineCode>{`) is `}<inlineCode parentName="td">{`header`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`document`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Document. It can be used when the component type (`}<inlineCode parentName="td">{`hsm.components.type`}</inlineCode>{`) is `}<inlineCode parentName="td">{`header`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video. It can be used when the component type (`}<inlineCode parentName="td">{`hsm.components.type`}</inlineCode>{`) is `}<inlineCode parentName="td">{`header`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text. It can be used when the component type (`}<inlineCode parentName="td">{`hsm.components.type`}</inlineCode>{`) is `}<inlineCode parentName="td">{`button`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`currency`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Currency`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date/time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`payload`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Payload. It can be used when the component type (`}<inlineCode parentName="td">{`hsm.components.type`}</inlineCode>{`) is `}<inlineCode parentName="td">{`button`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`HSMCurrency object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`currencyCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Currency code as defined in ISO 4217`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`amount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Amount with cents multiplied by 1000. So $100.99 would be `}<inlineCode parentName="td">{`100990`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`MessageTag object`}</h3>
    <p>{`Message tags allow messages to be marked with a particular tag. The meaning and effect of each tag depend on each specific platform.
Using Conversations API, it's possible to set a MessageBird Message Tag, which will be converted to a platform-specific message tag.
The table below displays the correspondence between MessageBird Message Tags and platform-specific message tags.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`MessageBird Message Tag`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Facebook Message Tag`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Platform-specific Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`event.update`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CONFIRMED_EVENT_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Send the user reminders or updates for an event they have registered for (e.g., RSVP'ed, purchased tickets).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`purchase.update`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`POST_PURCHASE_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Notify the user of an update on a recent purchase.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`account.update`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ACCOUNT_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Notify the user of a non-recurring change to their application or account.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`human_agent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`HUMAN_AGENT`}</inlineCode>{` (Closed BETA) `}<a parentName="td" {...{
              "href": "https://www.facebook.com/help/contact/?id=2616212338594331"
            }}>{`Apply for access`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows human agents to respond to user inquiries. Messages can be sent within 7 days after a user message.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`More details about Facebook Message Tags are available here: `}<a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags/"
      }}>{`https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags/`}</a></p>
    <h3>{`ReplyTo object`}</h3>
    <p>{`ReplyTo object is part of a message and contains a reference of another message in the conversation, for which the original message is a reply.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The id of the referenced message`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The supported platforms are shown in the table below. Attempting to send a message with a `}<inlineCode parentName="p">{`replyTo`}</inlineCode>{` object to an unsupported platform will result in  `}<inlineCode parentName="p">{`400 Bad Request`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Platform`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Receive replies`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Send replies`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Whatsapp (on premise)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Whatsapp (cloud hosted)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Telegram`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Clicks on buttons of `}<a parentName="p" {...{
        "href": "/api/conversations/#whatsappinteractive-object"
      }}>{`WhatsAppInteractive`}</a>{` and
`}<a parentName="p" {...{
        "href": "/api/conversations/#messagehsm-object"
      }}>{`MessageHSM`}</a>{` messages will include the `}<inlineCode parentName="p">{`replyTo.id`}</inlineCode>{` property set with the original
message ID. For more information about it, please refer to quickstarts about
`}<a parentName="p" {...{
        "href": "/quickstarts/whatsapp/send-interactive-messages/"
      }}>{`Send WhatsApp interactive message`}</a>{` and
`}<a parentName="p" {...{
        "href": "/quickstarts/whatsapp/send-message-with-buttons/"
      }}>{`Send WhatsApp template messages with buttons`}</a>{`.`}</p>
    <h3>{`WhatsAppOrder object`}</h3>
    <p>{`It represents an order made through a Single or Multi-Product message.`}</p>
    <Notification type="INFO" mdxType="Notification">
    WhatsApp order messages can't be sent, only received (inbound only).
    </Notification>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`catalog_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of the catalog that contains the products listed under `}<inlineCode parentName="td">{`product_items`}</inlineCode>{` sections.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`product_items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#whatsapporderproduct-object"
            }}>{`WhatsAppOrderProduct`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Product items`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text message sent along with the order.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppOrderProduct object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`product_retailer_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier (in the catalog) of the product.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`quantity`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of items purchased.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`item_price`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unitary price of the items.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`currency`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Currency of the price.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppText object`}</h3>
    <p>{`It represents a question made by the user about a specific product.`}</p>
    <Notification type="INFO" mdxType="Notification">
    WhatsApp text messages can't be sent, only received (inbound only).
    </Notification>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsapptextbody-object"
            }}>{`WhatsAppTextBody`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text message`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`context`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappcontext-object"
            }}>{`WhatsAppContext`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message context`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppTextBody object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message body`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppContext object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp ID of the sender of the original message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message ID of original message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`referred_product`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappreferredproduct-object"
            }}>{`WhatsAppReferredProduct`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for enquiries coming from a Product Detail Page, Single Product Messages, and Multi-Product Message.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppReferredProduct object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`catalog_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID for the catalog the item belongs to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`product_retailer_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier (in the catalog) of the product.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppInteractive object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappinteractivetype-object"
            }}>{`WhatsAppInteractiveType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. Interactive message type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappinteractiveheader-object"
            }}>{`WhatsAppInteractiveHeader`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` only for multi-product messages. Header content displayed on top of a message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappinteractivebody-object"
            }}>{`WhatsAppInteractiveBody`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. The body of the message. Emojis and markdown are supported.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappinteractiveaction-object"
            }}>{`WhatsAppInteractiveAction`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. Action you want the user to perform after reading the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`footer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappinteractivefooter-object"
            }}>{`WhatsAppInteractiveFooter`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Optional`}</strong>{`. The footer of the message. Emojis and markdown are supported.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`reply`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappinteractivereply-object"
            }}>{`WhatsAppInteractiveReply`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Available only when message is inbound`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppInteractiveType object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`list`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List messages. Messages including a menu of up to 10 options. This type of message offers a simpler and more consistent way for users to make a selection when interacting with a business.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`button`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reply buttons. Messages including up to 3 buttons. This type of message offers a quicker way for users to make a selection from a menu when interacting with a business. Reply buttons have the same user experience as interactive templates with buttons.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`product`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Single product messages`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`product_list`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Multi-product messages`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`button_reply`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Inbound only`}</strong>{`. Replies of reply buttons`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppInteractiveHeader object`}</h3>
    <p>{`Header content displayed on top of a message.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappinteractiveheadertype-object"
            }}>{`WhatsAppInteractiveHeaderType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. Define the header type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` if type is set to text. Text for the header. Formatting allows emojis, but not markdown. Maximum length: 60 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#media-object"
            }}>{`Media`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` if type is set to video.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#media-object"
            }}>{`Media`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` if type is set to image.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`document`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#media-object"
            }}>{`Media`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` if type is set to document.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppInteractiveHeaderType object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for List Messages, Reply Buttons and Multi-Product Messages`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for Reply Buttons and Multi-Product Messages`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for Reply Buttons and Multi-Product Messages`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`document`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for Reply Buttons and Multi-Product Messages`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppInteractiveBody object`}</h3>
    <p>{`The body of the message.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. The body content of the message. Emojis and markdown are supported. Links are supported. Maximum length: 1024 characters.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppInteractiveAction object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`catalog_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` for Single Product Messages and Multi-Product Messages. Unique identifier of the Facebook catalog linked to your WhatsApp Business Account. This ID can be retrieved via Commerce Manager.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`product_retailer_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` for Single Product Messages and Multi-Product Messages. Unique identifier of the product in a catalog. This can be retrieved via Commerce Manager.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sections`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#whatsappinteractivesection-object"
            }}>{`WhatsAppInteractiveSection`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` for List Messages and Multi-Product Messages. The minimum number of sections is 1 and maximum is 10.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`button`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` for List Messages. Button content. It cannot be an empty string and must be unique within the message. Does not allow emojis or markdown. Maximum length: 20 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`buttons`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#whatsappinteractivebutton-object"
            }}>{`WhatsAppInteractiveButton`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` for Reply Button Messages.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppInteractiveButton object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. Must be unique within buttons. Maximum length: 256 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. Only supported type `}<inlineCode parentName="td">{`reply`}</inlineCode>{` for Reply Button Messages. Maximum length: 20 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. Must be unique within buttons. Maximum length: 20 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image_url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`For inbound/fetching messages only`}</strong>{`. Use this URL to retrieve the image button.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppInteractiveSection object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` if the message has more than one section. Title of the section. Maximum length: 24 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rows`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#whatsappinteractivesectionrow-object"
            }}>{`WhatsAppInteractiveSectionRow`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` for List Messages.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`product_items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#whatsappinteractiveproduct-object"
            }}>{`WhatsAppInteractiveProduct`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` for Multi-Product Messages. There is a minimum of 1 product per section and a maximum of 30 products across all sections.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppInteractiveProduct object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`product_retailer_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. Unique identifier of the product in a catalog. This can be retrieved via Commerce Manager.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppInteractiveSectionRow object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. It must be unique within rows. Maximum length: 200 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. Row title text. Maximum length: 24 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Optional`}</strong>{`. Maximum length: 72 characters.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppInteractiveFooter object`}</h3>
    <p>{`The footer of the message.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. The footer content of the message. Emojis and markdown are supported. Links are supported. Maximum length: 60 characters.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppInteractiveReply object`}</h3>
    <p>{`Represents an interactive reply when message is inbound.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. The unique id of button reply or list message reply`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. The text content of reply or list message reply`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Optional`}</strong>{`. The description content of button reply or list message reply`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`WhatsAppSticker object`}</h3>
    <p>{`Represents a WhatsApp-specific sticker message, which have the following requirements/limitations:`}</p>
    <ul>
      <li parentName="ul">{`The image format must be `}<inlineCode parentName="li">{`image/webp`}</inlineCode></li>
      <li parentName="ul">{`The image size can't be bigger than 100 KB for static stickers and 500 KB for animated stickers`}</li>
      <li parentName="ul">{`The image dimensions must be exactly 512 x 512`}</li>
      <li parentName="ul">{`Animated stickers are supported only for On-Cloud channels`}</li>
      <li parentName="ul">{`Native first party and third party WhatsApp stickers are not supported for outbound messages`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{`. URL of the sticker image. The format must be `}<inlineCode parentName="td">{`image/webp`}</inlineCode>{` and the maximum size is 100 KB.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Email object`}</h3>
    <p>{`Email represents an email message and it's one of the content types available in Conversations API. The required fields are
`}<inlineCode parentName="p">{`to`}</inlineCode>{`, `}<inlineCode parentName="p">{`from`}</inlineCode>{`, `}<inlineCode parentName="p">{`subject`}</inlineCode>{` and `}<inlineCode parentName="p">{`content`}</inlineCode>{`. Before sending a message with attachments or inline images, you need to upload
them to the Messaging API and then use its IDs in the payload. For more information about how to upload attachments and
inline images, refer to the `}<a parentName="p" {...{
        "href": "/quickstarts/conversations/email-attachments-and-inline-images"
      }}>{`Upload attachments and inline images`}</a>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An unique random ID for this message on the MessageBird platform, returned upon acceptance of the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/conversations/#emailrecipient-object"
            }}>{`EmailRecipient`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. An array containing the recipients of the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cc`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/conversations/#emailrecipient-object"
            }}>{`EmailRecipient`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. An array containing the recipients which a copy of the message should be sent to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bcc`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/conversations/#emailrecipient-object"
            }}>{`EmailRecipient`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. An array containing the recipients which a blind copy of the message should be sent to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#emailrecipient-object"
            }}>{`EmailRecipient`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. Name / address that will be displayed in the from field of the sent messages, the domain in the address email must be registered to one of your channels.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`subject`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. This will be displayed as the subject in the message, expected in the UTF-8 charset without RFC2047 encoding.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#emailcontent-object"
            }}>{`EmailContent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. HTML or text content for the email. At least one type of content is required.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`replyTo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email address used to compose the email's "Reply-To" header.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`returnPath`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email address used to compose the email's "Return-Path" header. Must match your sending domain.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`headers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object containing custom headers other than Subject, From, To, and Reply-To. These will be sent along with your message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tracking`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#emailtracking-object"
            }}>{`EmailTracking`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Allows you to set tracking options.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`reportUrl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL for delivery of status reports for the message. Must use https.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`performSubstitutions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Perform substitutions of variables inside the content or headers of your email (Default: true).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`attachments`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#emailattachment-object"
            }}>{`EmailAttachment`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. List of files attached to a message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`inlineImages`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#emailinlineimage-object"
            }}>{`EmailInlineImage`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. List of inline images added to the message content.`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`EmailContent object`}</h5>
    <p>{`The email content object represents the content to be included as the body of your message, it can contain either HTML or plain text.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`html`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HTML content of the message, expected in UTF-8.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Plain text of the message, expected in UTF-8.`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`EmailRecipient object`}</h5>
    <p>{`You can specify the recipients of your email as an array of objects with the properties listed below.
Variables added for each recipient are used to customise the content sent to a user if a template with
matching dynamic placeholders is used in the body or custom headers. Additional variables not present in the content or headers are ignored.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. Valid email address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "mailto:demo@messagebird.com"
            }}>{`demo@messagebird.com`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name attached to the email address, this appears in the To field in a users email client`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jane Doe`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`variables`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of variables used for placeholders inside the content or headers of your email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{"firstname": "John", "lastname": "Doe"}`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h5>{`EmailTracking object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`open`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Adds a tracking pixel to handle `}<inlineCode parentName="td">{`message.opened`}</inlineCode>{` events. (Default: true)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`click`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Adds link-wrapping to handle `}<inlineCode parentName="td">{`link.clicked`}</inlineCode>{` events. (Default: true)`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`EmailAttachment object`}</h5>
    <p>{`The Attachment object represents a file attached to a particular message. The maximum attachment size is 20 MB.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attachment ID.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`URL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL of the file to be attached to the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`length`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File size in bytes.`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`EmailInlineImage object`}</h5>
    <p>{`The Inline Image object represents an image added to the message content. The maximum image size is 20 MB.`}</p>
    <p>{`In order to use the inline image in the content of the message, you must refer the `}<inlineCode parentName="p">{`contentId`}</inlineCode>{` using the `}<inlineCode parentName="p">{`img`}</inlineCode>{` tag.
For example: `}<inlineCode parentName="p">{`<img src="cid:<contentId>">`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inline image ID.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`URL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL of the file.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`length`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File size in bytes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`contentId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID used in the content of the message.`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`ConversationStatus object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`New inbound or outbound messages will be added to the conversation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`archived`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The conversation is archived, therefore, it won't receive any new messages.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`all`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can be used when listing conversations for fetching active and archived conversations.`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`MessageStatus object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Platform`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`accepted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message is accepted and it will be processed asynchronously.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message is pending to be delivered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message is sent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rejected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message is rejected. The reason should be available in the message details.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message failed to be delivered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`read`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message was read by the recipient.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`received`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`deleted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message is deleted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`All`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unknown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message status is temporarily unknown.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`transmitted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message was delivered to the `}<a parentName="td" {...{
              "href": "https://developers.facebook.com/docs/whatsapp/on-premises/get-started"
            }}>{`WhatsApp Business Client`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SMS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`delivery_failed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message failed to be delivered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SMS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`buffered`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message is enqueued to be delivered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SMS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`expired`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message was not delivered in time.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`clicked`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The recipient clicked in a tracked link in the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`opened`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The recipient opened the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bounce`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message was permanently rejected by the recipient's email server.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spam_complaint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message was marked as spam by the recipient.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`out_of_bounded`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The recipient's email server accepted the message, however, later it reported the message was not delivered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`delayed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The recipient's email server temporarily rejected the message (it will be retried).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`list_unsubscribe`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The recipient clicked the 'Unsubscribe' button in their email client.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dispatched`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message, which was addressed to multiple recipients, was delivered to Sparkpost (email provider).`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`MessageType object`}</h5>
    <h6>{`Generic message types`}</h6>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Image.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`audio`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Audio message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`file`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`event`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversation event, not a real message and won't visible by your contacts.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rich`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rich content message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`menu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menu message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`buttons`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message with buttons.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Link.`}</td>
        </tr>
      </tbody>
    </table>
    <h6>{`WhatsApp-specific message types`}</h6>
    <p>{`The following message types are supported only by WhatsApp channels.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Direction`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hsm`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp Template message (Highly Structured Message)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`whatsappSticker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp sticker message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound/outbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`interactive`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp interactive message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`whatsappOrder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp order message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`whatsappText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WhatsApp product question message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
        </tr>
      </tbody>
    </table>
    <h6>{`Instagram-specific message types`}</h6>
    <p>{`The following message types are supported only by Instagram channels.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`externalAttachment`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for story mentions.`}</td>
        </tr>
      </tbody>
    </table>
    <h6>{`Email-specific message types`}</h6>
    <p>{`The following message types are supported only by Email channels.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Direction`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`email`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound/outbound`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`MessageDirection object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A message sent from you to your contacts (outbound message).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`received`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A message received by you (inbound message).`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookMessage object`}</h5>
    <p>{`Facebook-specific message which provides quick replies, media templates and generic templates.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text to be sent with the quick reply. Maximum 2000 characters.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required if `}<inlineCode parentName="td">{`attachment`}</inlineCode>{` is not set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`attachment`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookattachment-object"
            }}>{`FacebookAttachment`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message attachment.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required if `}<inlineCode parentName="td">{`text`}</inlineCode>{` is not set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`quick_replies`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/conversations/#facebookquickreply-object"
            }}>{`FacebookQuickReply`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Quick reply buttons. It supports up to 13 quick replies`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookAttachment object`}</h5>
    <p>{`Facebook-specific message attachment.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookattachmenttype-object"
            }}>{`FacebookAttachmentType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attachment type.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`payload`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookattachmentpayload-object"
            }}>{`FacebookAttachmentPayload`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attachment payload`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookAttachmentType object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Image attachment type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`audio`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Audio attachment type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video attachment type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`file`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File attachment type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location attachment type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fallback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fallback attachment type. For example: a user shares a URL with a Facebook Page, then the attachment is created based on `}<a parentName="td" {...{
              "href": "https://developers.facebook.com/docs/sharing/web/"
            }}>{`link sharing`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`template`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template attachment type`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookAttachmentPayload object`}</h5>
    <p>{`Facebook-specific message attachment payload.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attachment URL.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`is_reusable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, then the attachment is may be sent to other recipients. The default value is `}<inlineCode parentName="td">{`false`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`attachment_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook attachment ID.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`template_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#tacebooktemplatetype-object"
            }}>{`FacebookTemplateType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template type.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`elements`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#facebookelement-object"
            }}>{`FacebookElement`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parts of a generic message template. Maximum 10 elements for generic templates (`}<inlineCode parentName="td">{`template_type`}</inlineCode>{` is `}<inlineCode parentName="td">{`generic`}</inlineCode>{`) and it must be exactly 1 for media templates (`}<inlineCode parentName="td">{`template_type`}</inlineCode>{` is `}<inlineCode parentName="td">{`media`}</inlineCode>{`).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image_aspect_ratio`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookimageaspectratio-object"
            }}>{`FacebookImageAspectRatio`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Image aspect ratio.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For generic templates`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookTemplateType object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`media`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Structured template with image or video, and an optional button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`generic`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Structured template message with image, text and buttons.`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookElement object`}</h5>
    <p>{`A Facebook generic template message supports up to 10 elements. At least one additional field must be set along with `}<inlineCode parentName="p">{`title`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`media_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookelementmediatype-object"
            }}>{`FacebookElementMediaType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Media type. It must be empty for generic templates (`}<inlineCode parentName="td">{`template_type`}</inlineCode>{` is `}<inlineCode parentName="td">{`generic`}</inlineCode>{`).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For media templates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`attachment_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attachment ID of the image or video. Can't be used if `}<inlineCode parentName="td">{`media_url`}</inlineCode>{` is set.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`media_url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attachment URL of the image or video. Can't be used if `}<inlineCode parentName="td">{`attachment_id`}</inlineCode>{` is set.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For media templates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`buttons`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#facebookbutton-object"
            }}>{`FacebookButton`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of buttons. Maximum of 3 buttons for generic templates (`}<inlineCode parentName="td">{`template_type`}</inlineCode>{` is `}<inlineCode parentName="td">{`generic`}</inlineCode>{`) and maximum 1 button for media templates (`}<inlineCode parentName="td">{`template_type`}</inlineCode>{` is `}<inlineCode parentName="td">{`media`}</inlineCode>{`).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template title. Maximum 80 characters.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For generic templates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`subtitle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template subtitle. Maximum 80 characters.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`default_action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookbutton-object"
            }}>{`FacebookButton`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The default action to be executed when the template is clicked.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image_url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The image URL to display in the template.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookElementMediaType object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video media template`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Image media template`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookButton object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookbuttontype-object"
            }}>{`FacebookButtonType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Button type.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL which the customer will see when clicking at the button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When `}<inlineCode parentName="td">{`type`}</inlineCode>{` is `}<inlineCode parentName="td">{`web_url`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text to be displayed in the button. Maximum of 20 characters.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When `}<inlineCode parentName="td">{`type`}</inlineCode>{` is `}<inlineCode parentName="td">{`postback`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`payload`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When `}<inlineCode parentName="td">{`type`}</inlineCode>{` is `}<inlineCode parentName="td">{`phone_number`}</inlineCode>{`, it must contain a valid phone number to be called when the button is clicked. When `}<inlineCode parentName="td">{`type`}</inlineCode>{` is `}<inlineCode parentName="td">{`postback`}</inlineCode>{`, it must contain the payload to be sent through `}<a parentName="td" {...{
              "href": "/api/conversations/#facebookpostback-object"
            }}>{`FacebookPostback`}</a>{`. Maximum of 1000 characters.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When `}<inlineCode parentName="td">{`type`}</inlineCode>{` is `}<inlineCode parentName="td">{`phone_number`}</inlineCode>{` or `}<inlineCode parentName="td">{`postback`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookButtonType object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`web_url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://developers.facebook.com/docs/messenger-platform/send-messages/buttons#url"
            }}>{`URL button`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`phone_number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://developers.facebook.com/docs/messenger-platform/send-messages/buttons#call"
            }}>{`Call button`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`postback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://developers.facebook.com/docs/messenger-platform/send-messages/buttons#postback"
            }}>{`Postback button`}</a></td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookImageAspectRatio object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`horizontal`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Horizontal aspect ratio (1.91:1). Default value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`square`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Square aspect ratio (1:1)`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookQuickReply object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookquickreplycontenttype-object"
            }}>{`FacebookQuickReplyContentType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content type.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text to be displayed in the quick reply. Maximum 20 characters.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When `}<inlineCode parentName="td">{`content_type`}</inlineCode>{` is `}<inlineCode parentName="td">{`text`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`payload`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Payload to be sent through an inbound message when the quick reply is clicked. Maximum 1000 characters.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When `}<inlineCode parentName="td">{`content_type`}</inlineCode>{` is `}<inlineCode parentName="td">{`text`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image_url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Image URL to be displayed in the quick reply. The image must have a minimum of 24px x 24px. Larger images will be cropped and resized.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When `}<inlineCode parentName="td">{`title`}</inlineCode>{` is not set`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookQuickReplyContentType object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text quick reply.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`user_phone_number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Displays a button to allow the recipient to send the phone number associated with their account.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`user_email`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Displays a button to allow the recipient to send the email associated with their account.`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookPostback object`}</h5>
    <p>{`Facebook-specific inbound-only message type that represents a recipient response to a button/quick reply.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Title of the button/option clicked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`payload`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`payload`}</inlineCode>{` parameter defined in the button that was clicked.`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookReferral object`}</h5>
    <p>{`Facebook-specific inbound-only message type that provides information about how the recipient got into an existing thread
via:`}</p>
    <ul>
      <li parentName="ul">{`An `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/discovery/m-me-links"
        }}>{`m.me link with a referral parameter`}</a></li>
      <li parentName="ul">{`Clicking on a `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/discovery/ctm-ads"
        }}>{`Facebook Messenger conversation advertisement`}</a></li>
      <li parentName="ul">{`Starting or resuming a conversation thread from the `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/discovery/facebook-chat-plugin"
        }}>{`Facebook Chat Plugin`}</a></li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reference string. Limited to alphanumeric characters, `}<inlineCode parentName="td">{`-`}</inlineCode>{`, `}<inlineCode parentName="td">{`_`}</inlineCode>{` and `}<inlineCode parentName="td">{`=`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookreferralsource-object"
            }}>{`FacebookReferralSource`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The source of the referral`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Referral type. Currently supports only `}<inlineCode parentName="td">{`OPEN_THREAD`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ad_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of the advertisement`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ads_context_data`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#facebookadscontextdata-object"
            }}>{`FacebookAdsContextData`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contextual information about the advertisement`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookReferralSource object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ADS`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://developers.facebook.com/docs/messenger-platform/discovery/ctm-ads"
            }}>{`Facebook Messenger conversation advertisement`}</a>{` referral source.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SHORTLINK`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://developers.facebook.com/docs/messenger-platform/discovery/m-me-links"
            }}>{`m.me link`}</a>{` referral source.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CUSTOMER_CHAT_PLUGIN`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://developers.facebook.com/docs/messenger-platform/discovery/facebook-chat-plugin"
            }}>{`Facebook Chat Plugin`}</a>{` referral source.`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`FacebookAdsContextData object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ad_title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Advertisement title.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`photo_url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL of the image from the advertisement`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video_url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Thumbnail URL of the video from the advertisement`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`post_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of the post`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`product_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Product ID from the advertisement`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`LineSticker object`}</h5>
    <p>{`The sticker object provides information about a sent or received sticker message through the Line platform.`}</p>
    <Notification type="INFO" mdxType="Notification">
    Due to Line app limitations, we are unable to provide a URL link to the sticker nor display the sticker itself.
    </Notification>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/conversations/#linestickertype-object"
            }}>{`LineStickerType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`packageId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`stickerId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for sticker type `}<inlineCode parentName="td">{`standard`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`keywords`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(Inbound only) keywords that describe the sticker`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(Inbound only) text entered by the user`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`LineStickerType object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`standard`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sends a sticker to a user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mission`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sends a mission sticker to a user`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example json for a sending a sticker message via the `}<inlineCode parentName="p">{`/v1/send`}</inlineCode>{` endpoint to a user`}</p>
    <CodeSnippet code={CODE.LINE_STICKER_SEND_REQUEST} mdxType="CodeSnippet" />
    <h5>{`WhatsAppCarouselCard object`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attributes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`card_index`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Card index in the carousel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`components`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array of `}<a parentName="td" {...{
              "href": "/api/conversations/#messagecomponent-object"
            }}>{`MessageComponent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Components of the carousel card`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2>{`Platforms`}</h2>
    <h3>{`Supported media types per platform`}</h3>
    <p>{`This section describes the media types supported by platforms and their restrictions.`}</p>
    <h4>{`WhatsApp`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Media`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Types`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Limit`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`audio`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`audio/aac`}</inlineCode>{`, `}<inlineCode parentName="td">{`audio/mp4`}</inlineCode>{`, `}<inlineCode parentName="td">{`audio/mpeg`}</inlineCode>{`, `}<inlineCode parentName="td">{`audio/amr`}</inlineCode>{`, `}<inlineCode parentName="td">{`audio/ogg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only opus codecs, base audio/ogg is not supported`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`document`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any valid MIME-type.*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*For `}<a parentName="td" {...{
              "href": "https://changelog.messagebird.com/conversations-launching-support-for-the-whatsapp-cloud-hosted-api-235839"
            }}>{`WhatApp Cloud API`}</a>{` channels valid mime types listed below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image/jpeg`}</inlineCode>{`, `}<inlineCode parentName="td">{`image/png`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sticker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image/webp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 KB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The sticker dimensions must be exactly 512x512 and animated stickers are not supported.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video/mp4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only H.264 video codec and AAC audio codec is supported. Only videos with a single audio stream are supported.`}</td>
        </tr>
      </tbody>
    </table>
    <Notification type="INFO" mdxType="Notification">
      <p>{`Valid document MIME types for `}<a parentName="p" {...{
          "href": "https://changelog.messagebird.com/conversations-launching-support-for-the-whatsapp-cloud-hosted-api-235839"
        }}>{`WhatApp Cloud API`}</a>{`:`}</p>
      <p><inlineCode parentName="p">{`text/plain`}</inlineCode>{`, `}<inlineCode parentName="p">{`application/pdf`}</inlineCode>{`, `}<inlineCode parentName="p">{`application/vnd.ms-powerpoint`}</inlineCode>{`, `}<inlineCode parentName="p">{`application/msword`}</inlineCode>{`, `}<inlineCode parentName="p">{`application/vnd.ms-excel`}</inlineCode>{`,
`}<inlineCode parentName="p">{`application/vnd.openxmlformats-officedocument.wordprocessingml.document`}</inlineCode>{`, `}<inlineCode parentName="p">{`application/vnd.openxmlformats-officedocument.presentationml.presentation`}</inlineCode>{`,
`}<inlineCode parentName="p">{`application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`}</inlineCode>{`.`}</p>
    </Notification>
    <h4>{`Instagram`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Media`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Types`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Limit`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`jpg`}</inlineCode>{`, `}<inlineCode parentName="td">{`gif`}</inlineCode>{`, `}<inlineCode parentName="td">{`png`}</inlineCode>{`, `}<inlineCode parentName="td">{`ico`}</inlineCode>{`, `}<inlineCode parentName="td">{`bmp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 MB`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Facebook`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Media`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Types`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Limit`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`png`}</inlineCode>{`, `}<inlineCode parentName="td">{`jpeg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The maximum resolution for images is 85 Megapixel.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mp4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`audio`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mp3`}</inlineCode>{`, `}<inlineCode parentName="td">{`wav`}</inlineCode>{`, `}<inlineCode parentName="td">{`ogg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`file`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`csv`}</inlineCode>{`, `}<inlineCode parentName="td">{`pdf`}</inlineCode>{`, ...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25 MB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Telegram`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Media`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Types`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Limit`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`png`}</inlineCode>{`, `}<inlineCode parentName="td">{`jpg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`max 10 MB for multipart form upload or 5 MB if posted as URL`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mp4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`max 50 MB for multipart form upload or 5 MB if posted as URL`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`audio`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mp3`}</inlineCode>{`, `}<inlineCode parentName="td">{`ogg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`max 50 MB for multipart form upload or 5 MB if posted as URL`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`file`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pdf`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`max 50 MB for multipart form upload or 5 MB if posted as URL`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`LINE`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Media`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Types`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Limit`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`audio`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`m4a`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 MB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mp4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 MB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`jpeg`}</inlineCode>{`, `}<inlineCode parentName="td">{`png`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10 MB`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`WeChat`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Media`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Types`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Limit`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`audio`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mp3`}</inlineCode>{`, `}<inlineCode parentName="td">{`amr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 MB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mp4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10 MB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`gif`}</inlineCode>{`, `}<inlineCode parentName="td">{`png`}</inlineCode>{` and `}<inlineCode parentName="td">{`jpg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 MB`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Viber`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Media`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Types`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Limit`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`png`}</inlineCode>{`, `}<inlineCode parentName="td">{`jpg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 MB`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Email`}</h4>
    <p>{`The entire payload (text + html + attachments + inline images) is limited to 20 MBs. It supports all types of file.`}</p>
    <h4>{`SMS`}</h4>
    <p>{`NA.`}</p>
    <h4>{`Twitter`}</h4>
    <p>{`NA.`}</p>
    <h4>{`Google Business Messages`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Media`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Types`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Limit`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`image`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`png`}</inlineCode>{`, `}<inlineCode parentName="td">{`jpg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5 MB`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      